@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url("http://fonts.gstatic.com/s/opensans/v10/cJZKeOuBrn4kERxqtaUH3T8E0i7KZn-EPnyo3HZu7kw.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url("http://fonts.gstatic.com/s/opensans/v10/MTP_ySUJH_bn48VBG8sNSnhCUOGz7vYGh680lGh-uXM.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("http://fonts.gstatic.com/s/opensans/v10/k3k702ZOKiLJc3WVjuplzHhCUOGz7vYGh680lGh-uXM.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("http://fonts.gstatic.com/s/opensans/v10/xjAJXh38I15wypJXxuGMBobN6UDyHWBl620a-IRfuBk.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans Semibold Italic"), local("OpenSans-SemiboldItalic"), url("http://fonts.gstatic.com/s/opensans/v10/PRmiXeptR36kaC0GEAetxn5HxGBcBvicCpTp6spHfNo.woff") format("woff"); }

@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("http://fonts.gstatic.com/s/opensans/v10/PRmiXeptR36kaC0GEAetxjqR_3kx9_hJXbbyU8S6IN0.woff") format("woff"); }

@font-face {
  font-family: 'learning curve pro';
  src: url("../fonts/learningcurve_ot-webfont.eot");
  src: url("../fonts/learningcurve_ot-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/learningcurve_ot-webfont.woff2") format("woff2"), url("../fonts/learningcurve_ot-webfont.woff") format("woff"), url("../fonts/learningcurve_ot-webfont.ttf") format("truetype"), url("../fonts/learningcurve_ot-webfont.svg#learning_curve_proregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'mathlete';
  src: url("../fonts/mathlete-skinny-webfont.eot");
  src: url("../fonts/mathlete-skinny-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/mathlete-skinny-webfont.woff2") format("woff2"), url("../fonts/mathlete-skinny-webfont.woff") format("woff"), url("../fonts/mathlete-skinny-webfont.ttf") format("truetype"), url("../fonts/mathlete-skinny-webfont.svg#mathleteskinny") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'mathlete';
  src: url("../fonts/mathlete-bulky-webfont.eot");
  src: url("../fonts/mathlete-bulky-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/mathlete-bulky-webfont.woff2") format("woff2"), url("../fonts/mathlete-bulky-webfont.woff") format("woff"), url("../fonts/mathlete-bulky-webfont.ttf") format("truetype"), url("../fonts/mathlete-bulky-webfont.svg#mathletebulky") format("svg");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'jardiland';
  src: url("../fonts/jardiland-regular-webfont.eot");
  src: url("../fonts/jardiland-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/jardiland-regular-webfont.woff2") format("woff2"), url("../fonts/jardiland-regular-webfont.woff") format("woff"), url("../fonts/jardiland-regular-webfont.ttf") format("truetype"), url("../fonts/jardiland-regular-webfont.svg#jardiland_regularregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'jardiland';
  src: url("../fonts/jardiland-regular_italic-webfont.eot");
  src: url("../fonts/jardiland-regular_italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/jardiland-regular_italic-webfont.woff2") format("woff2"), url("../fonts/jardiland-regular_italic-webfont.woff") format("woff"), url("../fonts/jardiland-regular_italic-webfont.ttf") format("truetype"), url("../fonts/jardiland-regular_italic-webfont.svg#jardiland_regularitalic") format("svg");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'jardiland';
  src: url("../fonts/jardiland-bold_italic-webfont.eot");
  src: url("../fonts/jardiland-bold_italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/jardiland-bold_italic-webfont.woff2") format("woff2"), url("../fonts/jardiland-bold_italic-webfont.woff") format("woff"), url("../fonts/jardiland-bold_italic-webfont.ttf") format("truetype"), url("../fonts/jardiland-bold_italic-webfont.svg#jardiland_regularbold_italic") format("svg");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'jardiland';
  src: url("../fonts/jardiland-bold-webfont.eot");
  src: url("../fonts/jardiland-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/jardiland-bold-webfont.woff2") format("woff2"), url("../fonts/jardiland-bold-webfont.woff") format("woff"), url("../fonts/jardiland-bold-webfont.ttf") format("truetype"), url("../fonts/jardiland-bold-webfont.svg#jardiland_regularbold") format("svg");
  font-weight: bold;
  font-style: normal; }
